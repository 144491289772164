import React from "react";
import useNavigation from "../../hooks/useNavigation";

const NextNavigation = ({ page, label, ...restProps }) => {
  const navigation = useNavigation();

  return (
    <div className="text-center mt-1">
      <button
        onClick={() => navigation(page)}
        className="btn btn-primary"
      >
        {label}
      </button>
    </div>
  );
};

export default NextNavigation;
