import NextNavigation from "../navigation/NextNavigation";
import { FINAL_TOUCHES, DRAWING } from "../../constants/constants";
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setFilterProperty,
  saveSortedValues,
  saveLevels,
} from "../../store/actions/imageFilter";
import { loadFile } from "../../store/actions/image";
import { setGrid } from "../../store/actions/grid";
import { matrices, cell_values } from "../../constants/constants";
import Slider from "rc-slider";
import { useTranslation } from "react-i18next";
import "rc-slider/assets/index.css";

const ImageFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const image = useSelector(({ image }) => image.image);
  const desiredGridSize = useSelector(
    ({ imageFilter }) => imageFilter.desiredGridSize
  );
  const fileName = useSelector(({ image }) => image.fileName);
  const invertImage = useSelector(({ imageFilter }) => imageFilter.invertImage);
  const threshold = useSelector(({ imageFilter }) => imageFilter.threshold);
  const tMinMax = useSelector(({ imageFilter }) => {
    return { min: imageFilter.thresholdMin, max: imageFilter.thresholdMax };
  });
  const sharpenMatrixIndex = useSelector(
    ({ imageFilter }) => imageFilter.sharpenMatrixIndex
  );
  const thresholdValue =
    ((tMinMax.max - tMinMax.min) * threshold) / 100 + tMinMax.min;
  const levels = useSelector(({ imageFilter }) => imageFilter.levels);
  const grid = useSelector(({ grid }) => grid.grid);
  const canvas = useRef(null);
  const invCanvas = useRef(null);
  // resulting grid dimensions, after pixelation
  const pixelatedWidth = useRef(0);
  const pixelatedHeight = useRef(0);
  const pixelsPerSample = useRef(0);
  const gridSize = useRef({ w: 0, h: 0 });

  const onChange = (field, value) => {
    dispatch(setFilterProperty(field, value));
  };
  useEffect(() => {
    const img1 = new Image();

    img1.onload = () => drawFunc();
    const drawFunc = () => {
      const w = img1.width;
      const h = img1.height;
      invCanvas.current.width = w;
      invCanvas.current.height = h;
      canvas.current.width = w;
      canvas.current.height = h;

      const ctx = invCanvas.current.getContext("2d");
      ctx.drawImage(img1, 0, 0);

      let pixelArr = ctx.getImageData(0, 0, w, h).data;
      // resulting gridsize default = 10
      const heightLarger = h > w ? true : false;
      const dominantSide = heightLarger ? h : w;
      // the size of each grid box in pixels. +2 due to convolution
      pixelsPerSample.current = Math.floor(
        dominantSide / (parseInt(desiredGridSize) + 2)
      );
      const height = Math.floor(h / pixelsPerSample.current);
      const width = Math.floor(w / pixelsPerSample.current);
      // not needed variable
      pixelatedWidth.current = width;
      pixelatedHeight.current = height;
      gridSize.current = { w: width, h: height };
      let blackAndWhite = [...Array(height)].map((x) => Array(width).fill(0));
      // p[0], p[1], p[2], p[3] => rgba
      // convolution matrix here -> result to grid
      // get black and white and pixelated grid, color values
      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          let avgSample = 0;
          for (let cy = 0; cy < pixelsPerSample.current; cy++) {
            for (let cx = 0; cx < pixelsPerSample.current; cx++) {
              let p =
                (x * pixelsPerSample.current +
                  cx +
                  y * pixelsPerSample.current * w +
                  cy) *
                4;
              const avgPixieValue =
                (pixelArr[p] + pixelArr[p + 1] + pixelArr[p + 2]) / 3;
              avgSample += avgPixieValue;
            }
          }
          blackAndWhite[y][x] =
            avgSample / (pixelsPerSample.current * pixelsPerSample.current);
        }
      }
      const valSet = getThreshold(blackAndWhite);
      let sorted = [...valSet].sort((a, b) => a - b);
      dispatch(saveSortedValues(sorted));
      dispatch(setFilterProperty("thresholdMin", Math.floor(sorted[0])));
      dispatch(
        setFilterProperty("thresholdMax", Math.ceil(sorted[sorted.length - 1]))
      );
      dispatch(saveLevels(blackAndWhite));
    };
    img1.src = image;
  }, [canvas, desiredGridSize, sharpenMatrixIndex, dispatch, image]);
  // generate grid
  useEffect(() => {
    if (
      !pixelatedHeight.current ||
      !pixelatedWidth.current ||
      !levels ||
      !levels.length
    )
      return;
    let convolutionResult = [...Array(pixelatedHeight.current - 2)].map((x) =>
      Array(pixelatedWidth.current - 2).fill(0)
    );
    const sharpenMatrix = matrices[sharpenMatrixIndex];
    if (levels.length < pixelatedHeight.current) {
      return;
    }
    for (let y = 1; y < pixelatedHeight.current - 1; y++) {
      for (let x = 1; x < pixelatedWidth.current - 1; x++) {
        let val = 0;
        for (let cy = 0; cy < sharpenMatrix.length; cy++) {
          for (let cx = 0; cx < sharpenMatrix[cy].length; cx++) {
            val += levels[y + cy - 1][x + cx - 1] * sharpenMatrix[cy][cx];
          }
        }
        val = val > thresholdValue ? cell_values.SQUARE : cell_values.EMPTY;
        if (invertImage) {
          if (val === cell_values.EMPTY) val = cell_values.SQUARE;
          else val = cell_values.EMPTY;
        }
        convolutionResult[y - 1][x - 1] = val;
      }
    }
    dispatch(
      setGrid(convolutionResult, gridSize.current.w - 2, gridSize.current.h - 2)
    );
  }, [pixelatedHeight, pixelatedWidth, levels, threshold, invertImage]);

  const getThreshold = (levels) => {
    let mySet = new Set();
    const sharpenMatrix = matrices[sharpenMatrixIndex];
    for (let y = 1; y < pixelatedHeight.current - 2; y++) {
      for (let x = 1; x < pixelatedWidth.current - 2; x++) {
        let val = 0;
        for (let cy = 0; cy < sharpenMatrix.length; cy++) {
          for (let cx = 0; cx < sharpenMatrix[cy].length; cx++) {
            val += levels[y + cy - 1][x + cx - 1] * sharpenMatrix[cy][cx];
          }
        }
        mySet.add(val);
      }
    }
    return mySet;
  };
  // paint grid
  useEffect(() => {
    const ctx2 = canvas.current.getContext("2d");
    for (let y = 0; y < pixelatedHeight.current - 2; y++) {
      for (let x = 0; x < pixelatedWidth.current - 2; x++) {
        const convVal = grid[y][x];
        let val = convVal === 0 ? 255 : 0;
        ctx2.fillStyle = `rgba(${val},${val},${val},${1})`;
        ctx2.fillRect(
          x * pixelsPerSample.current,
          y * pixelsPerSample.current,
          pixelsPerSample.current,
          pixelsPerSample.current
        );
      }
    }
  }, [grid]);

  const onChangeFile = (e) => {
    if (e.target) dispatch(loadFile(e.target.files[0]));
  };
  return (
    <React.Fragment>
      <div className="row">
        <aside className="col-sm-12 col-md-3">
          <div className="mb-3">
            <label>{t("content.uploadPage.web.chooseImage")}:</label>
            <div className="input-group">
              <input
                className="form-control "
                type="text"
                value={fileName}
                readOnly={true}
                onChange={(e) => onChange("desiredGridSize", e.target.value)}
              />
              <div className="btn btn-outline-primary">
                {t("buttons.browse")}
                <input
                  className=""
                  type="file"
                  accept=".svg,.jpg,.jpeg,.png"
                  onChange={(e) => onChangeFile(e)}
                  title="Upload image"
                  style={{
                    opacity: "0",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label>{t("content.uploadPage.web.maxDimensions")}:</label>
            <input
              className="form-control "
              type="number"
              value={desiredGridSize}
              onChange={(e) => onChange("desiredGridSize", e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label>{t("content.uploadPage.web.fill")}:</label>
            <Slider
              min={1}
              max={99}
              value={100 - threshold}
              onChange={(val) => onChange("threshold", 100 - val)}
            />
          </div>
          <input
            id="pix-input-invert-toggle"
            className="form-check-input"
            type="checkbox"
            value={invertImage}
            onChange={(e) => onChange("invertImage", e.target.checked)}
          />
          <label
            htmlFor="pix-input-invert-toggle"
            className="form-check-label ms-2"
          >
            <label>{t("content.uploadPage.web.invertImage")}:</label>
          </label>
        </aside>
        <div className="col-sm-12 col-md-9 row">
          <div className="col-6">
            <img style={{ width: "83%", height: "auto" }} src={image} />
          </div>
          <div className="col-6">
            <canvas
              style={{ width: "100%", height: "auto" }}
              ref={canvas}
            ></canvas>
          </div>
        </div>
        <canvas
          style={{ width: "auto", height: "400px", display: "none" }}
          ref={invCanvas}
        ></canvas>
      </div>
      <footer className="pix-footer">
        <NextNavigation label={t("buttons.edit")} page={DRAWING} />
        <NextNavigation label={t("buttons.next")} page={FINAL_TOUCHES} />
      </footer>
    </React.Fragment>
  );
};

export default ImageFilter;
