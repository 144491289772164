import React from "react";

const ViewWrapper = ({ className, children }) => {
  return (
    <div className={`container ${className ? className : ""}`}>
      {children}
    </div>
  );
};

export default ViewWrapper;
