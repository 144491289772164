import * as actionTypes from "./actionTypes";
import { validateNonZeroNumber } from "../../helpers/validators";
import { copyValues } from "../../components/grid-draw/clueCounter";
import { appendHistory } from "./history";

export const setGrid = (grid, gridWidth, gridHeight) => (
  dispatch,
  getState
) => {
  if (!grid || !gridWidth || !gridHeight) {
    return;
  }
  const { width, height } = {
    width: validateNonZeroNumber(gridWidth),
    height: validateNonZeroNumber(gridHeight),
  };
  if ((width, height)) {
    dispatch({
      type: actionTypes.SET_GRID,
      gridWidth: width,
      gridHeight: height,
      grid: grid,
    });
    dispatch(appendHistory());
  }
};

export const setDimensions = (gridWidth, gridHeight) => (
  dispatch,
  getState
) => {
  if (!gridWidth || !gridHeight) {
    return;
  }
  const { width, height } = {
    width: validateNonZeroNumber(gridWidth),
    height: validateNonZeroNumber(gridHeight),
  };
  if ((width, height)) {
    const { grid, gridWidth, gridHeight } = getState().grid;
    const newGrid = copyValues(grid, gridWidth, gridHeight, width, height);
    dispatch({
      type: actionTypes.SET_GRID,
      gridWidth: width,
      gridHeight: height,
      grid: newGrid,
    });
    dispatch(appendHistory());
  }
};

export const clearGrid = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CLEAR_GRID,
  });
  dispatch(appendHistory());
};

export const paintCell = (row, col, hideClues) => (dispatch, getState) => {
  const { xClueMaxLength, yClueMaxLength } = getState().gridEnhanced;
  dispatch({
    type: actionTypes.PAINT_CELL_TOGGLE,
    row: !hideClues ? parseInt(row) : parseInt(row - yClueMaxLength),
    col: !hideClues ? parseInt(col) : parseInt(col - xClueMaxLength),
  });
  dispatch(appendHistory());
};

export const setCellValue = (row, col, val, hideClues) => (dispatch, getState) => {
  const { xClueMaxLength, yClueMaxLength } = getState().gridEnhanced;
  dispatch({
    type: actionTypes.PAINT_CELL_VALUE,
    value: val,
    row: !hideClues ? parseInt(row) : parseInt(row - yClueMaxLength),
    col: !hideClues ? parseInt(col) : parseInt(col - xClueMaxLength),
  });
  dispatch(appendHistory());
};
