import React from "react";
import PropTypes from "prop-types";
import {
  clueHorizontalPositions,
  clueVerticalPositions,
} from "../../constants/constants";

const SelectOption = ({
  option,
  customHook,
  axis,
}) => {
  const { value, onChange } = customHook(option);
  const label =
    axis === "horizontal" ? clueHorizontalPositions : clueVerticalPositions;
  return (
    <React.Fragment>
      <div className="mb-1">
        {axis === "horizontal" ? "horizontal" : "vertical"}
      </div>
      <div className="form-check">
        <input
          id={`pix-input-${axis}-first-toggle`}
          className="form-check-input"
          type="checkbox"
          checked={value === 0}
          onChange={(e) => onChange(0)}
        />
        <label htmlFor={`pix-input-${axis}-first-toggle`} className="form-check-label">
          {label[0]}
        </label>
      </div>
      <div className="form-check">
        <input
          id={`pix-input-${axis}-second-toggle`}
          className="form-check-input"
          type="checkbox"
          checked={value === 1}
          onChange={(e) => onChange(1)}
        />
        <label htmlFor={`pix-input-${axis}-second-toggle`} className="form-check-label">
          {label[1]}
        </label>
      </div>
      <div className="form-check">
        <input
          id={`pix-input-${axis}-third-toggle`}
          className="form-check-input"
          type="checkbox"
          checked={value === 2}
          onChange={(e) => onChange(2)}
        />
        <label htmlFor={`pix-input-${axis}-third-toggle`} className="form-check-label">
          {label[2]}
        </label>
      </div>
    </React.Fragment>
  );
};

SelectOption.prototypes = {
  option: PropTypes.string.isRequired,
};

export default SelectOption;
