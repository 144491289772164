import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CLUE_POSITION, cell_values } from "../../constants/constants";
import { paintCell, setCellValue } from "../../store/actions/grid";
import { generateClues } from "../../store/actions/gridEnhanced";
import * as style from "./styles";
import TableData from "./TableData";
import TableRow from "./TableRow";

const Grid = ({
  interactive,
  id,
  showAnswer = true,
  hideClues = false,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const counter = useRef(0);
  const cellValueClicked = useRef(null);
  const { grid, gridWidth, gridHeight } = useSelector(({ grid }) => grid);
  const {
    snapToGrid,
    minorBorders,
    majorVerticalGridLines,
    majorHorizontalGridLines,
    verticalGridLinesBucket,
    horizontalGridLinesBucket,
    verticalCluePosition,
    horizontalCluePosition,
    clueColor,
    cornerColor,
    majorGridColor,
    backgroundColor,
  } = useSelector(({ gridOptions }) => gridOptions);
  const { xClueMaxLength, yClueMaxLength, xClues, yClues } = useSelector(
    ({ gridEnhanced }) => gridEnhanced
  );

  const onClickCell = (row, col) => {
    if (hideClues === true) {
      row = row + (yClueMaxLength ? yClueMaxLength : 0);
      col = col + (xClueMaxLength ? xClueMaxLength : 0);
    }
    dispatch(paintCell(row, col, hideClues));
  };
  const paintCellWithValue = (row, col, val) => {
    if (hideClues === true) {
      row = row + (yClueMaxLength ? yClueMaxLength : 0);
      col = col + (xClueMaxLength ? xClueMaxLength : 0);
    }
    dispatch(setCellValue(row, col, val, hideClues));
  };

  useEffect(() => {
    if (!hideClues) dispatch(generateClues());
  }, [grid, hideClues]);

  const getCellValueStyle = (key) => {
    let res = { ...style.cell };
    if (minorBorders) {
      res = { ...style.cell_minor_border };
    }
    switch (true) {
      case key === cell_values.CORNER:
        res = { ...style.cell_clue_pad };
        res.backgroundColor = cornerColor;
        break;
      case key === cell_values.EMPTY_CLUE:
        res = { ...style.cell_clue_pad };
        res.backgroundColor = clueColor;
        break;
      case key === cell_values.CLUE:
        res = { ...style.cell_corner };
        break;
      case key === cell_values.EMPTY:
        res = { ...style.cell, ...res };
        res.backgroundColor = backgroundColor;
        break;
      case key === cell_values.SQUARE && showAnswer:
        res = { ...style.cell_dark, ...res };
        break;
      case key === cell_values.SQUARE && showAnswer === false:
        res = { ...style.cell_dark, ...res };
        res.backgroundColor = backgroundColor;
        break;
      case key > 0:
      case key === null:
      case key === undefined:
        res = { ...style.cell_clue };
        res.backgroundColor = clueColor;
        break;
      default:
        break;
    }
    return res;
  };
  const getCellCoordStyle = (row, col, isHorisontalClue) => {
    let res = { ...style.cell };
    let leftOffset = 0;
    let topOffset = 0;
    // add vertical major lines
    if (col >= leftOffset && col < gridWidth + leftOffset) {
      if (isHorisontalClue === true) {
      } else if (
        (col - leftOffset) % horizontalGridLinesBucket === 0 &&
        majorHorizontalGridLines &&
        col !== leftOffset
      ) {
        res = { ...res, ...style.cell_major_vertical_line };
        res.borderLeft = `2px solid ${majorGridColor}`;
      }
    }
    // add horizontal major lines
    if (row >= topOffset && row < gridHeight + topOffset) {
      if (isHorisontalClue === false) {
        return res;
      }
      if (
        (row - topOffset) % verticalGridLinesBucket === 0 &&
        majorVerticalGridLines &&
        row !== topOffset
      ) {
        res = { ...res, ...style.cell_major_horizontal_line };
        res.borderTop = `2px solid ${majorGridColor}`;
      }
    }
    return res;
  };

  const getCombinedStyle = (val, row, col, isHorisontalClue) => {
    const cellStyle = getCellValueStyle(val);
    const borderStyle = getCellCoordStyle(row, col, isHorisontalClue);
    return { ...cellStyle, ...borderStyle };
  };

  const onKeyDown = (e) => {
    if (e.which !== 13) return;
    const id = e.target.id;
    const className = e.target.className;
    if (id && className === "clickable") {
      const [row, col] = e.target.id.split("_");
      if (row && col) {
        cellValueClicked.current =
          grid[row][col] === cell_values.EMPTY
            ? cell_values.SQUARE
            : cell_values.EMPTY;
        onClickCell(parseInt(row), parseInt(col));
      }
    }
  };

  const onMouseDown = (e) => {
    const id = e.target.id;
    const className = e.target.className;
    if (id && className === "clickable") {
      const [row, col] = e.target.id.split("_");
      if (row && col) {
        cellValueClicked.current =
          grid[row][col] === cell_values.EMPTY
            ? cell_values.SQUARE
            : cell_values.EMPTY;
        onClickCell(parseInt(row), parseInt(col));
      }
    }
  };
  const onMouseOver = (e) => {
    if (e.buttons == 1 || e.buttons == 3) {
      const id = e.target.id;
      const className = e.target.className;
      if (id && className === "clickable") {
        const [row, col] = e.target.id.split("_");
        if (
          row &&
          col &&
          cellValueClicked.current !== null &&
          cellValueClicked.current !== undefined &&
          !isNaN(cellValueClicked.current)
        ) {
          paintCellWithValue(
            parseInt(row),
            parseInt(col),
            cellValueClicked.current
          );
        }
      }
    }
    return;
  };

  const drawGrid = () => {
    counter.current = 0;
    if (grid)
      return grid.map((i, row) => (
        <TableRow key={row}>{drawColumns(i, row)}</TableRow>
      ));
  };

  const drawColumns = (element, row, isHorisontalClue) => {
    return element.map((i, col) => {
      if (i === cell_values.EMPTY || i === cell_values.SQUARE) {
        counter.current = counter.current + 1;
      }
      return (
        <TableData
          id={`${row}_${col}`}
          key={col}
          style={getCombinedStyle(i, row, col, isHorisontalClue)}
          className={
            i === cell_values.EMPTY || i === cell_values.SQUARE
              ? "clickable"
              : ""
          }
          onMouseDown={interactive ? onMouseDown : undefined}
          onMouseOver={interactive ? onMouseOver : undefined}
          onKeyDown={interactive ? onKeyDown : undefined}
          tabIndex={
            i === cell_values.EMPTY || i === cell_values.SQUARE
              ? counter.current
              : undefined
          }
        >
          {i > 0 ? i : undefined}
        </TableData>
      );
    });
  };
  const addPadding = (array, maxLength, concatStart = true) => {
    const copy = [...array];
    // array exists but is shorter or it doesnt exist (no clue on that col/row)
    for (let i = 0; i < copy.length; i++) {
      if (copy[i] && copy[i].length < maxLength) {
        const padding = Array(maxLength - copy[i].length).fill(
          cell_values.EMPTY_CLUE
        );
        if (concatStart) {
          copy[i] = padding.concat(copy[i]);
        } else {
          copy[i] = copy[i].concat(padding);
        }
      } else if (copy[i] && copy[i].length === maxLength) {
      } else {
        const padding = Array(maxLength).fill(cell_values.EMPTY_CLUE);
        copy[i] = [...padding];
      }
    }
    return copy;
  };
  const drawYClues = (reverseAlignment = false) => {
    if (yClues && yClueMaxLength) {
      let rotatedYClues = [...Array(yClueMaxLength)].map((i) => [...Array()]);
      let yCluesPadding = addPadding(yClues, yClueMaxLength, reverseAlignment);
      for (let i = 0; i < yCluesPadding.length; i++) {
        for (let j = 0; j < yClueMaxLength; j++) {
          rotatedYClues[j][i] = yCluesPadding[i][j];
        }
      }
      return rotatedYClues.map((i, row) => (
        <TableRow key={row}>{drawColumns(i, row, false)}</TableRow>
      ));
    }
  };
  const drawXClues = (reverseAlignment = false) => {
    if (xClues && xClueMaxLength) {
      let filledXClues = [...Array(xClues.length)].map((i) => [...Array()]);
      let xCluesPadding = addPadding(xClues, xClueMaxLength, !reverseAlignment);
      for (let i = 0; i < xCluesPadding.length; i++) {
        for (let j = 0; j < xClueMaxLength; j++) {
          filledXClues[i][j] =
            xCluesPadding[i][j] !== undefined && xCluesPadding[i][j] !== null
              ? xCluesPadding[i][j]
              : undefined;
        }
      }
      return filledXClues.map((i, row) => (
        <TableRow key={row}>{drawColumns(i, row, true)}</TableRow>
      ));
    }
  };
  const clueExists =
    !hideClues &&
    (xClues.some((f) => (f.length ? true : false)) ||
      yClues.some((f) => (f.length ? true : false)));
  const shouldRenderTopLeftCorner =
    !hideClues &&
    clueExists &&
    (verticalCluePosition === CLUE_POSITION.TOP_ONLY ||
      verticalCluePosition === CLUE_POSITION.BOTH_SIDES) &&
    (horizontalCluePosition === CLUE_POSITION.LEFT_ONLY ||
      horizontalCluePosition === CLUE_POSITION.BOTH_SIDES);
  const shouldRenderTopRightCorner =
    !hideClues &&
    clueExists &&
    (verticalCluePosition === CLUE_POSITION.TOP_ONLY ||
      verticalCluePosition === CLUE_POSITION.BOTH_SIDES) &&
    (horizontalCluePosition === CLUE_POSITION.RIGHT_ONLY ||
      horizontalCluePosition === CLUE_POSITION.BOTH_SIDES);
  const shouldRenderBottomLeftCorner =
    !hideClues &&
    clueExists &&
    (verticalCluePosition === CLUE_POSITION.BOTTOM_ONLY ||
      verticalCluePosition === CLUE_POSITION.BOTH_SIDES) &&
    (horizontalCluePosition === CLUE_POSITION.LEFT_ONLY ||
      horizontalCluePosition === CLUE_POSITION.BOTH_SIDES);
  const shouldRenderBottomRightCorner =
    !hideClues &&
    clueExists &&
    (verticalCluePosition === CLUE_POSITION.BOTTOM_ONLY ||
      verticalCluePosition === CLUE_POSITION.BOTH_SIDES) &&
    (horizontalCluePosition === CLUE_POSITION.RIGHT_ONLY ||
      horizontalCluePosition === CLUE_POSITION.BOTH_SIDES);
  return (
    <div className="text-center">
      <table className="d-inline-block" id={id}>
        <thead></thead>
        <tbody>
          <tr>
            {shouldRenderTopLeftCorner && (
              <td style={style.getCornerStyle(cornerColor)}></td>
            )}
            {verticalCluePosition !== CLUE_POSITION.BOTTOM_ONLY && clueExists && (
              <td className="clue-border-padding">
                <table>
                  <tbody>{drawYClues(snapToGrid)}</tbody>
                </table>
              </td>
            )}
            {shouldRenderTopRightCorner && (
              <td style={style.getCornerStyle(cornerColor)}></td>
            )}
          </tr>
          <tr>
            {horizontalCluePosition !== CLUE_POSITION.RIGHT_ONLY && clueExists && (
              <td className="clue-border-padding">
                <table>
                  <tbody>{drawXClues(!snapToGrid)}</tbody>
                </table>
              </td>
            )}
            <td>
              <table>
                <tbody>{drawGrid()}</tbody>
              </table>
            </td>
            {horizontalCluePosition !== CLUE_POSITION.LEFT_ONLY && clueExists && (
              <td className="clue-border-padding">
                <table>
                  <tbody>{drawXClues(snapToGrid)}</tbody>
                </table>
              </td>
            )}
          </tr>
          <tr>
            {shouldRenderBottomLeftCorner && (
              <td style={style.getCornerStyle(cornerColor)}></td>
            )}
            {verticalCluePosition !== CLUE_POSITION.TOP_ONLY && clueExists && (
              <td className="clue-border-padding">
                <table>
                  <tbody>{drawYClues(!snapToGrid)}</tbody>
                </table>
              </td>
            )}
            {shouldRenderBottomRightCorner && (
              <td style={style.getCornerStyle(cornerColor)}></td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Grid;
