import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { undo, redo } from "../store/actions/history";

const useKeyboard = () => {
  const dispatch = useDispatch();
  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === "z") {
      event.preventDefault();
      dispatch(undo());
    } else if (event.metaKey && !event.shiftKey && event.key === "z") {
      event.preventDefault();
      dispatch(undo());
    }
    // redo
    if (event.ctrlKey && event.shiftKey && event.key === "Z") {
      event.preventDefault();
      dispatch(redo());
    } else if (event.metaKey && event.shiftKey && event.key === "z") {
      event.preventDefault();
      dispatch(redo());
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
};
export default useKeyboard;
