import * as actionTypes from "./actionTypes";

export const appendHistory = () => (dispatch, getState) => {
  const { grid } = getState().grid;
  if (!grid) {
    return;
  }
  const timepoint = JSON.parse(
    JSON.stringify({
      grid: getState().grid,
    })
  );

  dispatch({ type: actionTypes.APPEND_HISTORY, timepoint });
};

export const undo = () => (dispatch, getState) => {
  const { history, historyIndex } = getState().history;
  if (history && history.length > 1 && historyIndex > 0) {
    const {
      grid: { grid, gridWidth, gridHeight },
    } = history[historyIndex - 1];
    dispatch({ type: actionTypes.UNDO, grid, gridWidth, gridHeight });
  }
};

export const redo = () => (dispatch, getState) => {
  const { history, historyIndex } = getState().history;
  if (history && history.length > 1 && historyIndex < history.length - 1) {
    const {
      grid: { grid, gridWidth, gridHeight },
    } = history[historyIndex + 1];
    dispatch({ type: actionTypes.REDO, grid, gridWidth, gridHeight });
  }
};
