import React from "react";
import Button from "./Button";

const HookButton = ({ hook, label, disabled, ...restProps }) => {
  const onClick = hook(restProps);
  return (
      <div className="mb-3">
      <Button
        disabled={disabled}
        className="btn btn-primary" {...restProps} onClick={onClick}>
        {label}
        </Button>
      </div>
  );
};

export default HookButton;
