import React from "react";

const TableRow = (props) => {
  return (
    <tr {...props} style={{ width: "20px", height: "20px" }}>
      {props.children}
    </tr>
  );
};

export default TableRow;
