import React from "react";
import PropTypes from "prop-types";

const Input = ({ option, customHook, ...restProps }) => {
  const { value, onChange } = customHook(option);

  return (
    <input
      className="form-control form-control-sm"
      type="number"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

Input.prototypes = {
  option: PropTypes.string.isRequired,
};

export default Input;
