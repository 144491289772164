import * as actionTypes from "./actionTypes";
import {setView} from "./app"
import { POST_PROCESSING } from "../../constants/constants"

export const loadImage = (image, fileName) => (dispatch, getState) => {
  dispatch({ type: actionTypes.SET_IMAGE, fileName, image });
};

export const loadFile = (file) => (dispatch, getState) => {
  var reader = new FileReader();
  reader.onload = function (event) {
    var imgObj = new Image();
    imgObj.src = event.target.result;
    if (file.size > 52428800) {
      alert("Maximum file size exceeded!");
      return;
    }
    const name = file.name;
    dispatch(loadImage(imgObj.src, name));
    dispatch(setView(POST_PROCESSING))
  };
  if (file) reader.readAsDataURL(file);
};
