import { useDispatch } from "react-redux";
import { setView } from "../store/actions/app";

const useNavigation = (option) => {
  const dispatch = useDispatch();

  const onChange = (val) => {
    dispatch(setView(val));
  };
  return onChange;
};
export default useNavigation;
