import React, { useState } from "react";
import { ChromePicker } from "react-color";
import { useSelector, useDispatch } from "react-redux";
import { setStringOption } from "../../store/actions/gridOptions";

const ColorPicker = ({
  option,
  className,
  style,
  secondaryOption,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const optionValue = useSelector(({ gridOptions }) => gridOptions[option]);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorHex, setColorHex] = useState(optionValue);

  const toggleOpen = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const close = () => {
    setDisplayColorPicker(false);
  };

  const selectColor = (val) => {
    setColorHex(val.hex);
    dispatch(setStringOption(option, val.hex));
    if (secondaryOption) {
      dispatch(setStringOption(secondaryOption, val.hex));
    }
  };
  const selectColorContinuous = (val) => {
    setColorHex(val.hex);
  };
  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };
  return (
    <div className={className} style={style}>
      <button
        className="me-2"
        style={{
          backgroundColor: colorHex,
          width: "12px",
          height: "12px",
          padding: 0,
          border: "1px solid",
          borderRadius: "20%",
          borderColor: "black"
        }}
        onClick={toggleOpen}>
        </button>
      {displayColorPicker ? (
        <div style={popover}>
          <div style={cover} onClick={close} />
          <ChromePicker
            disableAlpha={true}
            color={colorHex}
            onChange={selectColorContinuous}
            onChangeComplete={selectColor}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
