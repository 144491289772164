import React from "react";
import { useSelector } from "react-redux";
import {
  START,
  DRAWING,
  FINAL_TOUCHES,
  POST_PROCESSING,
} from "../../constants/constants";
import StartView from "./StartView";
import EditView from "./EditView";
import PostProcessingView from "./PostProcessView";
import FinishView from "./FinishView";
import BackNavigation from "../navigation/BackNavigation";
import LastNavigation from "../navigation/LastNavigation";
import ViewWrapper from "./ViewWrapper";

const View = (props) => {
  const status = useSelector(({ app }) => app.currentPage);
  const lastPage = useSelector(({ app }) => app.lastPage);

  const getView = () => {
    switch (status) {
      case START:
        return (
          <ViewWrapper className="pix-narrow pix-page-intro-content">
            <StartView />
          </ViewWrapper>
        );
      case POST_PROCESSING:
        return (
          <ViewWrapper className="pix-narrow pix-page-default-content">
            <BackNavigation page={START} />
            <PostProcessingView />
          </ViewWrapper>
        );
      case DRAWING:
        return (
          <ViewWrapper className="pix-narrow pix-page-default-content">
            <BackNavigation page={lastPage === POST_PROCESSING ? POST_PROCESSING : START} />
            <EditView />
          </ViewWrapper>
        );
      case FINAL_TOUCHES:
        return (
          <ViewWrapper className="pix-narrow pix-page-default-content">
            <LastNavigation />
            <FinishView />
          </ViewWrapper>
        );
      default:
        break;
    }
  };
  return (
    <React.Fragment>
      {getView()}
    </React.Fragment>
  );
};

export default View;
