import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setNumericOptionValue } from "../store/actions/gridOptions";

const useGridOptions = (option) => {
  const dispatch = useDispatch();
  const value = useSelector(({ gridOptions }) => gridOptions[option]);

  const onChange = (val) => {
    dispatch(setNumericOptionValue(option, val));
  };

  return { value, onChange };
};
export default useGridOptions;
