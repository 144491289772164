export const cell = {
  maxWidth: "20px",
  maxHeight: "20px",
  minWidth: "20px",
  minHeight: "20px",
  width: "20px",
  height: "20px",
  padding: "0",
  fontSize: "12px",
  textAlign: "center",
};
export const cell_minor_border = {
  borderLeft: "1px solid #ccc",
  borderRight: "1px solid #ccc",
  borderTop: "1px solid #ccc",
  borderBottom: "1px solid #ccc",
};

export const cell_dark = {
  ...cell,
  backgroundColor: "#000000",
};

export const cell_clue = {
  ...cell,
  backgroundColor: "#a8ccff",
  borderLeft: "1px solid #a8ccff",
  borderRight: "1px solid #a8ccff",
  borderTop: "1px solid #a8ccff",
  borderBottom: "1px solid #a8ccff",
};

export const cell_corner = {
  ...cell,
  backgroundColor: "#e4f0ff",
  borderLeft: "1px solid #e4f0ff",
  borderRight: "1px solid #e4f0ff",
  borderTop: "1px solid #e4f0ff",
  borderBottom: "1px solid #e4f0ff",
};

export const cell_clue_pad = {
  ...cell,
  backgroundColor: "#a8ccff",
  borderLeft: "1px solid #a8ccff",
  borderRight: "1px solid #a8ccff",
  borderTop: "1px solid #a8ccff",
  borderBottom: "1px solid #a8ccff",
};

export const getCornerStyle = (color) => {
  return {
    ...cell,
    backgroundColor: color,
    borderLeft: `1px solid ${color}`,
    borderRight: `1px solid ${color}`,
    borderTop: `1px solid ${color}`,
    borderBottom: `1px solid ${color}`,
  };
};
export const cell_major_vertical_line = {
  borderLeft: "1px solid #2f66a4",
};

export const cell_major_horizontal_line = {
  borderTop: "1px solid #2f66a4",
};
