export const validateNonZeroNumber = (num) => {
  if (num === null || num === undefined || num == 0) {
    return null;
  }
  try {
    return parseInt(num);
  } catch (error) {
    return null;
  }
};

export const validateNumber = (num) => {
  if (num === null || num === undefined) {
    return null;
  }
  try {
    return parseInt(num);
  } catch (error) {
    return null;
  }
};
