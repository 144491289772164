import { useDispatch } from "react-redux";
import { setDimensions } from "../store/actions/grid";

/**
 * hook to clear grid with
 * @returns onClick with CLEAR_GRID action
 */
const useCreateNewGrid = ({ width, height, ...restProps }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    if (width && height) dispatch(setDimensions(width, height));
  };

  return onClick;
};
export default useCreateNewGrid;
