import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { loadFile } from "../store/actions/image";

const useInput = () => {
  const dispatch = useDispatch();
  const inputRef = useRef();

  const onChangeFile = (e) => {
    if (e.target) dispatch(loadFile(e.target.files[0]));
  };

  const InputElement = () => (
    <input
      ref={inputRef}
      type="file"
      accept=".svg,.jpg,.jpeg,.png"
      onChange={(e) => onChangeFile(e)}
      style={{
        display: "none",
      }}
    />
  );

  return { InputElement, inputRef };
};
export default useInput;
