import * as actionTypes from "./actionTypes";
import { countAlongAxis } from "../../components/grid-draw/clueCounter";

// generate grid and clues
export const generateClues = () => (dispatch, getState) => {
  const { grid } = getState().grid;
  if (grid) {
    dispatch(calculateClues());
    dispatch(calculateMaxClueLengths());
  }
};

export const calculateClues = () => (dispatch, getState) => {
  const { grid, gridWidth, gridHeight } = getState().grid;

  const xClues = countAlongAxis("x", grid, gridWidth, gridHeight);
  const yClues = countAlongAxis("y", grid, gridWidth, gridHeight);
  dispatch({ type: actionTypes.SET_X_AXIS_CLUES, xClues });
  dispatch({ type: actionTypes.SET_Y_AXIS_CLUES, yClues });
};

export const calculateMaxClueLengths = () => (dispatch, getState) => {
  const { xClues, yClues } = getState().gridEnhanced;
  const xClueMaxLength = Math.max.apply(
    Math,
    xClues.map((m) => m.length)
  );
  const yClueMaxLength = Math.max.apply(
    Math,
    yClues.map((m) => m.length)
  );
  dispatch({ type: actionTypes.SET_X_MAX_CLUES, xClueMaxLength });
  dispatch({ type: actionTypes.SET_Y_MAX_CLUES, yClueMaxLength });
};
