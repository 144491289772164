import * as actionTypes from "./actionTypes";
import { validateNumber } from "../../helpers/validators";

export const setFilterProperty = (field, value) => (dispatch, getState) => {
  if (value !== null && value !== undefined)
    dispatch({ type: actionTypes.SET_FILTER_OPTION, field, value });
};

export const addToHistogram = (value) => (dispatch, getState) => {
  const { distribution } = getState().imageFilter;
  distribution[value] =
    distribution[value] !== undefined ? distribution[value] + 1 : 1;
  dispatch({
    type: actionTypes.SET_FILTER_OPTION,
    distribution: JSON.parse(JSON.stringify(distribution)),
  });
};

export const saveSortedValues = (values) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.SET_FILTER_OPTION,
    field: "sortedValues",
    value: values,
  });
};

export const saveLevels = (levels) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.SET_FILTER_OPTION,
    field: "levels",
    value: levels,
  });
};
