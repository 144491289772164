import React from "react";
import Grid from "../grid-draw/Grid";
import ColorPicker from "../finish-designer/ColorPicker";
import Toggle from "../finish-designer/Toggle";
import Input from "../inputs/Input";
import SelectOption from "../inputs/Select";
import useGridOptions from "../../hooks/useGridOptions";
import html2canvas from "html2canvas";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { useTranslation } from "react-i18next";

const FinishView = () => {
  const { t } = useTranslation();
  const printGrid = () => {
    var divToPrint = document.getElementById("grid");
    toPng(divToPrint).then((dataUrl) => {
      var a = document.createElement("a");
      a.href = dataUrl;
      a.download = "picross.png";
      document.body.appendChild(a);
      a.click();
    });
    // html2canvas(divToPrint, { scrollY: -window.scrollY, scale: 4 }).then(
    //   (canvas) => {
    //     const data = canvas.toDataURL("png", 1);
    //     var a = document.createElement("a");
    //     a.href = data;
    //     a.download = "pic-a-pix.png";
    //     document.body.appendChild(a);
    //     a.click();
    //   }
    // );
  };
  return (
    <React.Fragment>
      <div className="row">
        <aside className="col-md-4 pix-options-wrapper">
          <p>{t("content.finalizePage.web.optionsTitle")}</p>
          <div id="pix-options-general" className="row">
            <div className="col-6">
              <Toggle id="pix-input-snap-toggle" option="snapToGrid">
                <label
                  htmlFor="pix-input-snap-toggle"
                  className="form-check-label ms-2"
                >
                  {t("content.finalizePage.web.snap")}
                </label>
              </Toggle>
            </div>
            <div className="col-6">
              <Toggle id="pix-input-border-toggle" option="minorBorders">
                <label
                  htmlFor="pix-input-border-toggle"
                  className="form-check-label ms-2"
                >
                  {t("content.finalizePage.web.minorBorders")}
                </label>
              </Toggle>
            </div>
          </div>
          <div id="pix-options-color" className="row">
            <p>{t("content.finalizePage.web.colorOptions")}</p>
            <div className="col-6">
              <ColorPicker
                className="d-inline"
                option="clueColor"
                secondaryOption="cornerColor"
              />
              {t("content.finalizePage.web.hints")}
            </div>
            <div className="col-6">
              <ColorPicker className="d-inline" option="cornerColor" />
              {t("content.finalizePage.web.corner")}
            </div>
            <div className="col-6">
              <ColorPicker className="d-inline" option="majorGridColor" />
              {t("content.finalizePage.web.majorGrid")}
            </div>
            <div className="col-6">
              <ColorPicker className="d-inline" option="backgroundColor" />
              {t("content.finalizePage.web.background")}
            </div>
          </div>
          <div id="pix-options-clue-pos">
            <p>{t("content.finalizePage.web.cluePositionOption")}</p>
            <div className="row">
              <div className="col-6">
                <SelectOption
                  option="horizontalCluePosition"
                  customHook={useGridOptions}
                  axis={"horizontal"}
                />
              </div>
              <div className="col-6">
                <SelectOption
                  option="verticalCluePosition"
                  customHook={useGridOptions}
                  axis={"vertical"}
                />
              </div>
            </div>
          </div>
          <div id="pix-options-gridlines">
            <p>{t("content.finalizePage.web.majorGridLineOptions")}</p>
            <div className="row">
              <div className="col-6">
                <Toggle
                  id="pix-input-horizontal-grid-toggle"
                  option={"majorHorizontalGridLines"}
                >
                  <label
                    htmlFor="pix-input-horizontal-grid-toggle"
                    className="form-check-label ms-2"
                  >
                    {t("content.finalizePage.web.horizontal")}
                  </label>
                </Toggle>
              </div>
              {/* <div className="col-2">
                <label>{t("content.finalizePage.web.density")}</label>
              </div> */}
              <div className="col-6 pe-5">
                <Input
                  customHook={useGridOptions}
                  option="horizontalGridLinesBucket"
                ></Input>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Toggle
                  id="pix-input-vertical-grid-toggle"
                  option="majorVerticalGridLines"
                >
                  <label
                    htmlFor="pix-input-vertical-grid-toggle"
                    className="form-check-label ms-2"
                  >
                    {t("content.finalizePage.web.vertical")}
                  </label>
                </Toggle>
              </div>
              {/* <div className="col-2">
                <label>{t("content.finalizePage.web.density")}</label>
              </div> */}
              <div className="col-6 pe-5">
                <Input
                  customHook={useGridOptions}
                  option="verticalGridLinesBucket"
                ></Input>
              </div>
            </div>
          </div>
        </aside>
        <div className="pix-main-content col-md-8">
          <div className="pix-grid-wrapper">
            <Grid
              showAnswer={false}
              hideClues={false}
              interactive={false}
              id="grid"
            />
          </div>
        </div>
        <footer className="pix-footer">
          <button className="btn btn-primary" onClick={printGrid}>
            {t("buttons.print")}
          </button>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default FinishView;
