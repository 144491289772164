import React from "react";
import useNavigation from "../../hooks/useNavigation";
import { BackArrow } from "../../icons/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LastNavigation = ({ ...restProps }) => {
  const lastPage = useSelector(({ app }) => app.lastPage);
  const navigation = useNavigation();
  const { t } = useTranslation();

  return (
    <div className="p-0">
      <button
        onClick={() => navigation(lastPage)}
        style={{ width: "40px", height: "40px" }}
        className="btn p-0 d-md-none"
        {...restProps}
      >
        <BackArrow />
      </button>
      <button
        onClick={() => navigation(lastPage)}
        className="btn ps-0 btn-link d-none d-md-block"
      >
        {t("buttons.back")}
      </button>
    </div>
  );
};

export default LastNavigation;
