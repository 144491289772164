import { useDispatch } from "react-redux";
import { clearGrid } from "../store/actions/grid";

/**
 * hook to clear grid with
 * @returns onClick with CLEAR_GRID action
 */
const useClearGrid = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(clearGrid());
  };

  return onClick;
};
export default useClearGrid;
