import {
  cell_values,
} from "../../constants/constants";

export const countAlongAxis = (axis, grid, gridWidth, gridHeight) => {
  let axisClues = [];
  let isCounting = false;
  let clueLength = 0;
  let clueStore = [];
  for (let i = 0; i < (axis === "x" ? gridHeight : gridWidth); i++) {
    for (let j = 0; j < (axis === "x" ? gridWidth : gridHeight); j++) {
      if (isCounting) {
        if (grid[axis === "x" ? i : j][axis === "x" ? j : i] === cell_values.SQUARE) {
          clueLength++;
        } else {
          isCounting = false;
          clueStore.push(clueLength);
          clueLength = 0;
        }
      } else {
        if (grid[axis === "x" ? i : j][axis === "x" ? j : i] === cell_values.SQUARE) {
          isCounting = true;
          clueLength = 1;
        } else {
        }
      }
    }
    if (isCounting) {
      clueStore.push(clueLength);
    }
    isCounting = false;
    clueLength = 0;
    axisClues[i] = [...clueStore];
    clueStore = [];
  }
  return axisClues;
};

// export const createGridWithClues = (
//   grid,
//   gridWidth,
//   gridHeight,
//   xClues,
//   yClues,
//   xClueMaxLength,
//   yClueMaxLength
// ) => {
//   let gridWithClues = [...Array(gridHeight + yClueMaxLength)].map((x) =>
//     Array(gridWidth + xClueMaxLength).fill(null)
//   );
//   for (let i = 0; i < gridHeight + yClueMaxLength; i++) {
//     for (let j = 0; j < gridWidth + xClueMaxLength; j++) {
//       // vertical clues on top right
//       if (i < yClueMaxLength && j > xClueMaxLength) {
//         gridWithClues[i][j] = yClues[j - xClueMaxLength]
//           ? yClues[j - xClueMaxLength][yClueMaxLength - i]
//             ? yClues[j - xClueMaxLength][yClueMaxLength - i]
//             : null
//           : null;
//       }
//       // horizontal clues on bottom left
//       else if (j < xClueMaxLength && i > yClueMaxLength) {
//         gridWithClues[i][j] = xClues[i - yClueMaxLength]
//           ? xClues[i - yClueMaxLength][xClueMaxLength - j]
//             ? xClues[i - yClueMaxLength][xClueMaxLength - j]
//             : null
//           : null;
//       }
//       // bottom right grid
//       else if (i >= yClueMaxLength && j >= xClueMaxLength) {
//         gridWithClues[i][j] = grid[i - yClueMaxLength][j - xClueMaxLength];
//       } else {
//         gridWithClues[i][j] = null;
//       }
//     }
//   }
//   return gridWithClues;
// };

// naive scale, keep shapes at same coords
export const copyValues = (oldGrid, oldWidth, oldHeight, width, height) => {
  const newGrid = [...Array(height)].map((_x) => Array(width).fill(0));
  const maxLength = oldWidth < width ? oldWidth : width;
  const maxHeight = oldHeight < width ? oldHeight : width;
  for (let i = 0; i < maxHeight; i++) {
    for (let j = 0; j < maxLength; j++) {
      if (newGrid[i] && newGrid[i][j] !== undefined) {
        newGrid[i][j] = oldGrid[i][j];
      }
    }
  }
  return newGrid;
};
