import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setToggleOption } from "../../store/actions/gridOptions";
import PropTypes from "prop-types";

const Toggle = ({ option, label, id, inverse = false, ...restProps }) => {
  const dispatch = useDispatch();
  const optionValue = useSelector(({ gridOptions }) => gridOptions[option]);

  const toggleOption = () => {
    dispatch(setToggleOption(option));
  };
  return (
    <React.Fragment>
      <input
        id={id}
        className="form-check-input"
        type="checkbox"
        checked={inverse === true ? !optionValue : optionValue}
        onChange={toggleOption}
      />
      {restProps.children}
    </React.Fragment>
  );
};

Toggle.prototypes = {
  option: PropTypes.string.isRequired,
};

export default Toggle;
