import * as actionTypes from "./actionTypes";
import { validateNumber } from "../../helpers/validators";

export const setToggleOption = (optionString) => (dispatch, getState) => {
  const option = getState().gridOptions[optionString];
  dispatch({ type: actionTypes.SET_GRID_OPTIONS, [optionString]: !option });
};

export const setNumericOptionValue = (optionString, value) => (
  dispatch,
  getState
) => {
  const res = validateNumber(value);
  if (res !== null && res !== undefined)
    dispatch({ type: actionTypes.SET_GRID_OPTIONS, [optionString]: res });
};

export const setStringOption = (optionString, value) => (
  dispatch,
  getState
) => {
  if (value)
    dispatch({ type: actionTypes.SET_GRID_OPTIONS, [optionString]: value });
};

export const hideClues = () => (dispatch, getState) => {
  dispatch({ type: actionTypes.SET_GRID_OPTIONS, hideClues: true });
};

export const showClues = () => (dispatch, getState) => {
  dispatch({ type: actionTypes.SET_GRID_OPTIONS, hideClues: false });
};
