import React from "react";

const Button = ({...restProps}) => {
  return (
    <button {...restProps} >
    {restProps.children}
    </button>
  );
};

export default Button;
