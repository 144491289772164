import React, { useState } from "react";
import { setDimensions } from "../../store/actions/grid";
import { useSelector, useDispatch } from "react-redux";
import Grid from "../grid-draw/Grid";
import useKeyboard from "../../hooks/keyboard";
import HookButton from "../inputs/HookButton";
import NextNavigation from "../navigation/NextNavigation";
import { FINAL_TOUCHES } from "../../constants/constants";
import Toggle from "../finish-designer/Toggle";
import { useTranslation } from "react-i18next";
import clearGrid from "../../hooks/useClearGrid";
import useCreateNewGrid from "../../hooks/useCreateNewGrid";

const EditView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { gridWidth, gridHeight } = useSelector(({ grid }) => grid);
  const { hideClues } = useSelector(({ gridOptions }) => gridOptions);
  const [width, setWidth] = useState(gridWidth);
  const [height, setHeight] = useState(gridHeight);
  useKeyboard();

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      dispatch(setDimensions(width, height));
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <aside className="col-sm-12 col-md-4">
          <div className="mb-3">
            <label>{t("content.drawPage.web.width")}:</label>
            <input
              className="form-control "
              type="number"
              value={width}
              placeholder={gridWidth}
              onKeyDown={onKeyDown}
              onChange={(e) => setWidth(parseInt(e.target.value))}
            />
          </div>
          <div className="mb-3">
            <label>{t("content.drawPage.web.height")}:</label>
            <input
              className="form-control "
              type="number"
              value={height}
              placeholder={gridHeight}
              onKeyDown={onKeyDown}
              onChange={(e) => setHeight(parseInt(e.target.value))}
            />
          </div>
          <Toggle id="pix-input-clue-toggle" option="hideClues" inverse={true}>
            <label
              htmlFor="pix-input-clue-toggle"
              className="form-check-label mb-3 ms-2"
            >
              {t("content.drawPage.web.hideClues")}
            </label>
          </Toggle>
          <div className="pix-group">
            <HookButton
              hook={useCreateNewGrid}
              label={t("buttons.createNewGrid")}
              width={width}
              height={height}
              disabled={width == gridWidth && height == gridHeight}
            />
            <HookButton hook={clearGrid} label={t("buttons.clear")} />
          </div>
        </aside>
        <div className="mt-sm-2 mt-md-0 col-sm-12 col-md-8 pix-main-content">
          <div className="pix-grid-wrapper">
            <Grid hideClues={hideClues} interactive={true} />
          </div>
        </div>
      </div>
      <footer className="pix-footer">
        <NextNavigation label={t("buttons.next")} page={FINAL_TOUCHES} />
      </footer>
    </React.Fragment>
  );
};

export default EditView;
