import React from "react";
import View from "../components/views/View";
import "../style/style.scss";
import NavBar from "../components/common/navbar";
import { Helmet } from "react-helmet";
/**
 * everything happens here
 */
export const IndexPage = () => {

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Free online picross puzzle generator - Pix</title>
        <meta
          name="description"
          content="Pix is a free online picross, also known as nonogram, puzzle generator. To create a game, either draw or upload an image and then share with your friends to solve!"
        ></meta>
      </Helmet>
      <NavBar />
      <main className="container-fluid p-0">
        <View />
      </main>
    </React.Fragment>
  );
};

export default IndexPage;
