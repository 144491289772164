import React from "react";
import { setView } from "../../store/actions/app";
import { loadFile } from "../../store/actions/image";
import { useDispatch } from "react-redux";
import { DRAWING } from "../../constants/constants";
import { Hand, Upload } from "../../icons/icons";
import useInput from "../../hooks/useInput";
import { useTranslation } from "react-i18next";

const StartView = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { InputElement } = useInput();
  const updateView = (page) => {
    dispatch(setView(page));
  };
  const onChangeFile = (e) => {
    if (e.target) dispatch(loadFile(e.target.files[0]));
  };
  const aboutLink = () => {
    window.location = `${i18n.language}/about`
  }
  return (
    <React.Fragment>
      <h1 style={{ fontSize: "60px" }}>{t("content.frontPage.web.title")}</h1>
      <div className="row">
        <p
          className="text-center col-md-6 text-md-start mt-4 fw-bold d-none d-md-block"
          style={{ fontSize: "24px" }}
        >
          {t("content.frontPage.web.subTitle")}
        </p>
        <div className="col-sm-12 col-md-6 mt-4 pix-media-wrapper">
          <div className="pix-tertiary-bkg-mobile pix-box-mobile flex-column align-items-center">
            <p className="d-md-none d-sm-block">
              {t("content.frontPage.mobile.subTitle")}
            </p>
            <div style={{ display: "flex" }} className="pix-card-wrapper">
              <div className="card shadow border-0 rounded-2 pix-card pix-primary-bkg" onClick={() => updateView(DRAWING)}>
                <button
                  className="btn pix-card-btn"
                >
                  <div
                    className="card-img-top text-center ms-auto me-auto"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <Hand />
                  </div>
                  <div className="pix-card-body">
                    <p className="card-text">
                      {t("content.frontPage.web.option1")}
                    </p>
                  </div>
                </button>
              </div>
              <div className="d-flex"></div>
              <div className="card shadow border-0 rounded-2 pix-card pix-secondary-bkg">
                <div className="btn pix-card-btn">
                  <input
                    className="pix-input-btn"
                    type="file"
                    accept=".svg,.jpg,.jpeg,.png"
                    onChange={(e) => onChangeFile(e)}
                    title="Upload image"
                    style={{
                      opacity: "0",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <div
                    className="card-img-top text-center ms-auto me-auto"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <Upload />
                  </div>
                  <div className="pix-card-body">
                    <p className="card-text">
                      {t("content.frontPage.web.option2")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className="mt-4">
        <u style={{fontSize: "20px"}}>{t("content.frontPage.web.detail")}</u>
      </p>
      <button className="btn-dark rounded-3 px-1 pb-1" onClick={aboutLink}>
        {t("content.frontPage.web.additionalLink")}
      </button>
      <InputElement />
    </React.Fragment>
  );
};

export default StartView;
